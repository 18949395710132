<template>
  <div class="filmdetails">
    <!-- 视频播放部分 -->
    <div class="px-88 py-50 film_top">
      <div style="height: 5.625rem"></div>
      <div class="flex">
        <!-- 视频播放器 -->
        <div v-if="info.video" class="videobox">
          <videoplay
            :videoData="videoData"
            :src="info.video"
            :poster="info.img"
            :autoplay="autoplay"
            :film_id="info.id"
            @timeupdate="timeupdate"
          ></videoplay>
          <div class="is_paybox" v-if="info.is_buy == 1">
            <div class="text-white font-26 mb-40">应版权方要求，本片所有用户均需付费</div>
            <div class="is_paybox_btn cursorp" @click="openvip">
              会员{{ info.vip_money }}（原价{{ info.money }}元）
            </div>
          </div>
        </div>
        <!-- 评论 -->
        <div class="comment">
          <div class="px-35 pt-45 pb-30 text-white">
            <div class="title font-32 font-weight-bolder mb-15">
              {{ info.name }}
            </div>
            <div class="font-14">点播次数：{{ info.play_nums }}</div>
          </div>
          <div class="px-40 py-25 flex align-center bg-333333">
            <div
              class="mr-45 flex align-center cursorp"
              :class="info.is_fabulous == 1 ? 'text-FF524C' : 'text-white'"
              @click="giveLike"
            >
              <div class="mr-15">
                <!-- <img
                  src="@/assets/img/zan_act.png"
                  width="1.625rem"
                  height="1.5rem"
                  alt=""
                  v-if="info.is_fabulous == 1"
                /> -->
                <el-image
                  :src="require('@/assets/img/zan_act.png')"
                  style="width: 1.625rem; height: 1.5rem"
                  fit="cover"
                  v-if="info.is_fabulous == 1"
                ></el-image>
                <!-- <img
                  src="@/assets/img/zan.png"
                  width="1.625rem"
                  height="1.5rem"
                  alt=""
                  v-else
                /> -->
                <el-image
                  :src="require('@/assets/img/zan.png')"
                  style="width: 1.625rem; height: 1.5rem"
                  fit="cover"
                  v-else
                ></el-image>
              </div>
              点赞
            </div>
            <div
              class="flex align-center cursorp"
              :class="info.is_collection == 1 ? 'text-FF524C' : 'text-white'"
              @click="givecollect"
            >
              <div class="mr-15">
                <!-- <img
                  src="@/assets/img/collect_act.png"
                  alt=""
                  v-if="info.is_collection == 1"
                  width="1.5625rem"
                  height="1.5625rem"
                /> -->
                <el-image
                  :src="require('@/assets/img/collect_act.png')"
                  style="width: 1.5625rem; height: 1.5625rem"
                  fit="cover"
                  v-if="info.is_collection == 1"
                ></el-image>
                <el-image
                  :src="require('@/assets/img/collect.png')"
                  style="width: 1.5625rem; height: 1.5625rem"
                  fit="cover"
                  v-else
                ></el-image>
                <!-- <img
                  src="@/assets/img/collect.png"
                  width="1.5625rem"
                  height="1.5625rem"
                  alt=""
                  v-else
                /> -->
              </div>
              <span v-if="info.is_collection == 1">已收藏</span>
              <span v-else>收藏</span>
            </div>
          </div>
          <div class="py-25">
            <div class="px-40 font-20 text-white comment_title mb-30">
              <div class="comment_title_box"></div>
              <div class="comment_title_text">
                <span class="font-weight-bolder">好评指数</span> {{ info.star }}分
              </div>
            </div>
            <div class="comment_box px-35" @scroll="handleScroll($event)">
              <div
                v-for="(item, index) in comment"
                :key="index"
                class="flex align-center"
              >
                <!-- <img
                  :src="item.avatar"
                  width="3.3125rem"
                  height="3.3125rem"
                  class="circle"
                  alt=""
                /> -->
                <el-image
                  class="circle"
                  :src="item.avatar"
                  style="width: 3.3125rem; height: 3.3125rem"
                  fit="cover"
                ></el-image>
                <div
                  class="ml-25 py-25 border-bottom border-262629 x-1 font-14 text-999999"
                >
                  <div class="mb-15">
                    {{ item.nickname }}
                  </div>
                  <div class="flex align-center justify-between">
                    <div>
                      <el-rate
                        v-model="item.star"
                        disabled
                        void-color="#CCCCCC"
                        disabled-void-color="#CCCCCC"
                        allow-half
                        :colors="['#FFC002', '#FFC002', '#FFC002']"
                      >
                      </el-rate>
                    </div>
                    <div>
                      {{ item.create_time }}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- 视频内容 -->
    <div class="border-bottom border-EDEDED flex">
      <div class="pl-88 pr-40 pt-40 pb-35 border-right border-EDEDED w1040">
        <div class="font-42 text-222222 font-weight-bolder mb-30">
          {{ info.name }}
        </div>
        <div class="mb-50">
          <div class="mb-30 font-14 text-AAAAAA cursorp" @click="textflag = !textflag">
            <span class="font-14 text-333333 font-weight-bolder mr-10">本片介绍</span>
            <i class="el-icon-arrow-up" v-if="textflag"></i>
            <i class="el-icon-arrow-down" v-else></i>
          </div>
          <div v-if="textflag" class="font-14 text-333333">
            {{ info.introduce }}
          </div>
          <div v-else class="Multi-row-truncation font-14 text-333333">
            {{ info.introduce }}
          </div>
        </div>
        <div class="mb-108">
          <div class="mb-30 font-14 text-AAAAAA cursorp" @click="textflag2 = !textflag2">
            <span class="font-14 text-333333 font-weight-bolder mr-10">评论与欣赏</span>
            <i class="el-icon-arrow-up" v-if="textflag2"></i>
            <i class="el-icon-arrow-down" v-else></i>
          </div>
          <div v-if="textflag2" class="font-14 text-333333">
            {{ info.appreciation }}
          </div>
          <div v-else class="Multi-row-truncation font-14 text-333333">
            {{ info.appreciation }}
          </div>
        </div>
        <div class="flex align-center justify-center">
          <div class="flex align-end mr-60">
            <!-- <img
              src="@/assets/img/iconselezan.png"
              alt=""
              width="2.1875rem"
              height="1.875rem"
              v-if="info.is_fabulous == 1"
            /> -->
            <el-image
              class="circle"
              :src="require('@/assets/img/iconselezan.png')"
              style="width: 2.1875rem; height: 1.875rem"
              fit="cover"
              v-if="info.is_fabulous == 1"
            ></el-image>
            <el-image
              class="circle"
              :src="require('@/assets/img/iconzan.png')"
              style="width: 2.1875rem; height: 1.875rem"
              fit="cover"
              v-else
            ></el-image>
            <!-- <img
              src="@/assets/img/iconzan.png"
              alt=""
              width="2.1875rem"
              height="1.875rem"
              v-else
            /> -->
            <div class="font-20 text-666666 ml--8">
              {{ info.fabulous_nums }}
            </div>
          </div>
          <div class="flex align-end mr-60">
            <!-- <img
              src="@/assets/img/iconpin.png"
              alt=""
              width="2.1875rem"
              height="1.875rem"
            /> -->
            <el-image
              :src="require('@/assets/img/iconpin.png')"
              style="width: 2.1875rem; height: 1.875rem"
              fit="cover"
            ></el-image>
            <div class="font-20 text-666666 ml--8">
              {{ info.comment_nums }}
            </div>
          </div>
          <div class="flex align-end">
            <!-- <img
              src="@/assets/img/iconshoucangban.png"
              alt=""
              width="2rem"
              height="2rem"
            /> -->
            <el-image
              :src="require('@/assets/img/iconshoucangban.png')"
              style="width: 2rem; height: 2rem"
              fit="cover"
            ></el-image>
            <div class="font-20 text-666666 ml--8">
              {{ info.collection_nums }}
            </div>
          </div>
        </div>
      </div>
      <div class="px-45 py-45">
        <div class="font-20 text-222222 font-weight-bolder mb-25">好评指数</div>
        <!-- 确定打分 -->
        <div v-if="info.is_buy != 1">
          <div v-if="info.is_comment != 1">
            <div class="haopin mb-35">
              <el-rate
                v-model="value1"
                void-color="#CCCCCC"
                disabled-void-color="#CCCCCC"
                allow-half
                :colors="['#FFC002', '#FFC002', '#FFC002']"
                void-icon-class="el-icon-star-on"
              ></el-rate>
            </div>
            <div class="btn" @click="confirmscore">确定打分</div>
          </div>
          <div class="haopin" v-else>
            <el-rate
              disabled
              v-model="info.my_star"
              void-color="#CCCCCC"
              disabled-void-color="#CCCCCC"
              allow-half
              :colors="['#FFC002', '#FFC002', '#FFC002']"
              void-icon-class="el-icon-star-on"
            ></el-rate>
          </div>
        </div>
      </div>
    </div>
    <!-- 为你推荐 -->
    <div class="pt-50 pb-60 px-88">
      <div class="font-26 text-222222 font-weight-bolder mb-40">为你推荐</div>
      <div class="flex flex-wrap">
        <div
          v-for="(item, index) in film"
          :key="index"
          class="mr-15 mb-30 cursorp"
          @click="gofilmdetails(item.id)"
        >
          <div style="width: 12.3125rem; height: 16.9375rem">
            <el-image
              class="rounded3"
              style="width: 12.3125rem; height: 16.9375rem"
              :src="item.img_shu"
              fit="cover"
            ></el-image>
          </div>
          <!-- <img :src="item.img" width="12.3125rem" height="16.9375rem" class="rounded3" alt="" /> -->
          <div class="text-ellipsis font-14 w197 mt-20 font-weight-bolder">
            {{ item.name }}
          </div>
          <div class="text-ellipsis font-12 w197 text-999999 mt-10">
            {{ item.abstract }}
          </div>
        </div>
      </div>
    </div>
    <!-- 支付电影 -->
    <el-dialog :visible.sync="dialogVisible" width="30%">
      <div class="flex flex-column align-center justify-center">
        <div class="font-20 text-222222 mb-15">
          {{ info.name }}
        </div>
        <div class="font-14 text-FF524C mb-30">有效期至：{{ enddate }}</div>
        <div class="text-FF524C font-20">
          <span v-if="user_level_id == 2" class="font-42 font-weight-bolder">{{
            info.vip_money
          }}</span>
          <span v-else class="font-42 font-weight-bolder">{{ info.money }}</span
          >元
        </div>
        <div v-if="user_level_id == 2" class="font-14 text-999999">
          已优惠{{ concessional }}元
        </div>
        <div class="mb-10 flex align-center mt-40">
          <div
            class="flex align-center font-16 mr-20"
            :class="paytype == 1 ? 'paytypeact' : 'paytype'"
            @click="paybtn(1)"
          >
            <el-image
              :src="require('@/assets/img/ye.png')"
              style="width: 2.25rem; height: 2.25rem"
              fit="cover"
            ></el-image>
            <!-- <img src="@/assets/img/ye.png" width="2.25rem" height="2.25rem" alt="" /> -->
            <div class="ml-10">钱包余额支付</div>
          </div>
          <div
            class="flex align-center font-16"
            :class="paytype == 4 ? 'paytypeact' : 'paytype'"
            @click="paybtn(4)"
          >
            <!-- <img
              src="@/assets/img/zhufubao.png"
              width="2.25rem"
              height="2.25rem"
              alt=""
            /> -->
            <el-image
              :src="require('@/assets/img/zhufubao.png')"
              style="width: 2.25rem; height: 2.25rem"
              fit="cover"
            ></el-image>
            <div class="ml-10">支付宝</div>
          </div>

          <!-- <div
            class="flex align-center font-16"
            :class="paytype == 2 ? 'paytypeact' : 'paytype'"
            @click="paybtn(2)"
          >
            <img src="@/assets/img/weixin.png" width="2.25rem" height="2.25rem" alt="" />
            <div class="ml-10">微信</div>
          </div> -->
        </div>
        <div class="mb-40">
          <div
            class="flex align-center font-16 mr-20"
            :class="paytype == 5 ? 'paytypeact' : 'paytype'"
            @click="paybtn(5)"
          >
            <!-- <img
              src="@/assets/img/xiaofeijin.png"
              width="2.25rem"
              height="2.25rem"
              alt=""
            /> -->
            <el-image
              :src="require('@/assets/img/xiaofeijin.png')"
              style="width: 2.25rem; height: 2.25rem"
              fit="cover"
            ></el-image>
            <div class="ml-10">消费金支付</div>
          </div>
        </div>
        <!-- <div class="mb-40 flex align-center">
          <div
            class="flex align-center font-16 mr-20"
            :class="paytype == 3 ? 'paytypeact' : 'paytype'"
            @click="paybtn(3)"
          >
            <img src="@/assets/img/zhufubao.png" width="2.25rem" height="2.25rem" alt="" />
            <div class="ml-10">支付宝</div>
          </div>
          <div
            class="flex align-center font-16"
            :class="paytype == 4 ? 'paytypeact' : 'paytype'"
            @click="paybtn(4)"
          >
            <img src="@/assets/img/card.png" width="2.25rem" height="2.25rem" alt="" />
            <div class="ml-10">银行卡</div>
          </div>
        </div> -->
        <div class="paybtn" @click="Determinepayment">确认支付</div>
      </div>
    </el-dialog>
    <!-- 抽奖 -->
    <div class="lottery">
      <el-dialog :visible.sync="dialogVisible2" :show-close="false">
        <div class="flex">
          <!-- 抽奖 -->
          <div style="display: flex; justify-content: center" class="lottery1">
            <!-- <lottery-list
              ref="lottery1"
              @onend="onend"
              :list="list"
              @onsubmit="request('lottery1')"
            ></lottery-list> -->
            <lattice-lottery-cope
              ref="lottery1"
              @onend="onend"
              :list="list"
              @onsubmit="request('lottery1')"
            ></lattice-lottery-cope>
            <!-- 抽奖说明 -->
            <div class="Drawthat" @click="DrawthatBnt">抽奖说明</div>
            <div class="DrawNum">
              {{ DrawNum }}
            </div>
            <!-- 中奖明细 -->
            <div class="zhongjiangminxi cursorp" @click="dialogVisible4 = true">
              <img src="@/assets/img/zhongjiangminxi.png" alt="" />
            </div>
          </div>
          <!-- 中奖名单 -->
          <div class="zjorder">
            <div class="flex align-center justify-between px-20 py-10">
              <div></div>
              <div class="font-20 text-CCCCCC cursorp" @click="dialogVisible2 = false">
                <i class="el-icon-close"></i>
              </div>
            </div>
            <div class="flex align-center justify-center">
              <!-- <img
                src="@/assets/img/zjorder.png"
                width="16.1875rem"
                height="2.8125rem"
                alt=""
              /> -->
              <el-image
                :src="require('@/assets/img/zjorder.png')"
                style="width: 16.1875rem; height: 2.8125rem"
                fit="cover"
              ></el-image>
            </div>
            <div class="flex justify-center">
              <div class="lottery_box" v-if="prize && prize.length">
                <Scrollinform2 :informList="prize"></Scrollinform2>
              </div>
            </div>
          </div>
        </div>
      </el-dialog>
    </div>
    <!-- 抽奖说明 -->
    <el-dialog :visible.sync="dialogVisible3" width="30%" title="抽奖说明" center>
      <div class="" v-html="Drawthattext"></div>
    </el-dialog>
    <!-- 中奖明细 -->
    <el-dialog :visible.sync="dialogVisible4" width="30%" title="中奖明细" center>
      <ul
        class="infinite-list minxi"
        v-infinite-scroll="load"
        style="overflow: auto"
        :infinite-scroll-immediate="false"
      v-if="list2&&list2.length">
        <li v-for="(item, index) in list2" :key="index" class="infinite-list-item">
          <div class="minxi_date">
            {{ item.date_time }}
          </div>
          <div class="minxi_ul">
            <div
              v-for="(list, indexs) in item.list"
              :key="indexs"
              class="minxi_li flex align-center justify-between"
            >
              <div>
                <div class="mb-15 text-26252D">
                  {{ list.title }}
                </div>
                <div class="font-14 text-5E5D65">
                  {{ list.create_time }}
                </div>
              </div>
              <div class="text-FF6253 font-18">
                <span v-if="list.plus_reduce == 1">+</span><span v-else>-</span>
                {{ list.balance }}
              </div>
            </div>
          </div>
        </li>
      </ul>
    </el-dialog>
    <!-- 二维码支付 -->
    <el-dialog title="支付" :visible.sync="show" width="35%">
      <div class="flex align-center justify-center">
        <!-- <img :src="code" width="31.25rem" height="31.25rem" class="mx-auto" alt="" /> -->
        <el-image
          :src="code"
          class="mx-auto"
          style="width: 31.25rem; height: 31.25rem"
          fit="cover"
        ></el-image>
      </div>
    </el-dialog>
  </div>
</template>

<script>
import latticeLotteryCope from "@/components/lattice-lottery-cope/packages/Lottery/src/lottery-list";
import req from "../../utils/req";
import videoplay from "/src/components/videoplay";
import Scrollinform2 from "../../components/Scrollinform2.vue";
export default {
  components: {
    videoplay,
    Scrollinform2,
    latticeLotteryCope
  },
  watch: {
    // 被侦听的变量count
    $route: {
      handler() {
        let moveid = this.$route.query.moveid;
        if (moveid) {
          this.moveid = moveid;
          this.getdata();
        }
      },
      deep: true,
    },
  },
  data() {
    return {
      list2: [],
      dialogVisible4: false,
      Drawthattext: "",
      prize: [],
      DrawNum: 0,
      is_open: 0,
      list: [],
      paytype: 1,
      dialogVisible: false,
      dialogVisible2: false,
      dialogVisible3: false,
      autoplay: true,
      value1: 0,
      textflag: false,
      textflag2: false,
      videoData: {
        currentTime: 0, //学时
        duration: 0, //总时长
      },
      user_level_id: localStorage.getItem("user_level_id"),
      moveid: "",
      page: 1,
      info: "",
      film: [],
      comment: [],
      concessional: 0,
      enddate: 0,
      code: "",
      query_url: "",
      show: false,
      page2: 1,
      studyTime: {
        currentTime: 0, // 当前已学时长
        duration: 0, // 总时长
      },
    };
  },
  mounted() {
    let moveid = this.$route.query.moveid;
    if (moveid) {
      this.moveid = moveid;
      this.page = 1;
      this.getdata(1);
    }
    this.drawSet();
    this.getdrawSetList();
    this.page2 = 1;
    this.getmyLog();
  },
  beforeRouteLeave(to, from, next) {
    console.log("to", to);
    console.log("from", from);
    this.addLog();
    // console.log('next',next);
    // 在离开前执行一些操作，例如提示用户保存数据
    // ...
    // 执行next()，表示继续进行路由切换
    next();
  },
  methods: {
    timeupdate(e) {
      // console.log(e);
      this.studyTime.currentTime = e.target.currentTime;
      this.studyTime.duration = e.target.duration ? e.target.duration : 0;
    },
    // 增加观赏记录
    addLog() {
      let that = this;
      req
        .post("index/addLog", {
          film_id: that.info.id,
          node: that.studyTime.currentTime,
          long: that.studyTime.duration,
        })
        .then((res) => {
          if (res.code != 200) {
            console.log(res);
            // that.$message.error(res.msg);
            return;
          }
        })
        .catch((err) => {
          console.log(err);
          // that.$message.error(err.msg);
        });
    },
    load() {
      this.page2 = this.page2 + 1;
      this.getmyLog();
    },
    getmyLog() {
      let that = this;
      req
        .post("center/myLog", {
          type: 4,
          page: this.page2,
        })
        .then((res) => {
          if (res.code != 200) {
            // that.$message.error(res.msg);
            return;
          }
          if (this.page2 == 1) {
            that.list2 = res.data.data;
          } else {
            if (res.data.data && res.data.data.length) {
              that.list2 = [...that.list2, ...res.data.data];
            } else {
              // that.$message.error("暂无更多数据");
            }
          }
        })
        .catch((err) => {
          console.log(err);
          // that.$message.error(err.msg);
        });
    },
    getdrawSetList() {
      let that = this;
      req
        .post("index/drawGoods", {})
        .then((res) => {
          if (res.code != 200) {
            // that.$message.error(res.msg);
            return;
          }
          that.list = res.data;
          console.log("list", that.list);
        })
        .catch((err) => {
          console.log(err);
          // that.$message.error(err.msg);
        });
    },
    drawSet() {
      let that = this;
      req
        .post("index/drawSet", {})
        .then((res) => {
          if (res.code != 200) {
            // that.$message.error(res.msg);
            return;
          }
          that.is_open = res.data.is_open;
        })
        .catch((err) => {
          console.log(err);
          // that.$message.error(err.msg);
        });
    },
    // obj包括接口参数、接口地址、请求类型
    // successFun成功回调
    // errorFn失败回调
    // flag 同步、异步   true-异步
    httpAjax(obj, successFun, errorFn) {
      var xmlHttp = new XMLHttpRequest();
      var httpMethod = obj.method.toUpperCase();
      var httpUrl = obj.url;
      var requestData = JSON.stringify(obj.data) || {};
      xmlHttp.onreadystatechange = () => {
        //每当 readyState 属性改变时，就会调用该函数
        if (xmlHttp.readyState === 4) {
          //请求成功执行的回调函数
          successFun(xmlHttp.responseText);
        } else {
          errorFn(xmlHttp.responseText);
        }
      };

      //请求接口
      if (httpMethod == "GET") {
        xmlHttp.open("GET", httpUrl, obj.flag);
        xmlHttp.send(requestData);
      } else if (httpMethod == "POST") {
        xmlHttp.open("POST", httpUrl, obj.flag);
        xmlHttp.setRequestHeader("Content-Type", "application/json");
        xmlHttp.send(requestData);
      }
    },

    // 定时监测状态
    paytime() {
      // window.clearInterval(this.timer);
      // 调用方式
      let that = this;
      let params = {
        flag: true, //异步请求
        data: {}, //接口数据
        url: that.query_url,
        method: "get",
      };
      that.httpAjax(
        params,
        function (res) {
          let data = JSON.parse(res);
          if (data.status == "pending") {
            console.log("交易正在处理，请稍后");
          } else if (data.status == "succeeded") {
            that.show = false;
            window.clearInterval(that.timer);
            if (that.info.film_type == 2) {
              // 隐藏抽奖
              // if (that.is_open == 1) {
              //   that.dialogVisible2 = true;
              // } else {
                that.$message.success("支付成功");
              // }
            }
            that.page = 1;
            that.getdata();
          } else if (data.status == "failed") {
            that.show = false;
            window.clearInterval(that.timer);
            that.$message.error("支付失败");
            that.page = 1;
            that.getdata();
          }
        },
        function () {
          console.log("返回错误");
        }
      );
    },
    // 切换支付方式
    paybtn(id) {
      this.paytype = id;
    },
    // 确定支付
    Determinepayment() {
      let that = this;
      // let price = "";
      // if (this.user_level_id == 2) {
      //   price = this.info.vip_money;
      // } else {
      //   price = this.info.money;
      // }
      req
        .post("pay/orderPay", {
          film_id: that.info.id,
          type: that.paytype,
        })
        .then((res) => {
          if (res.code != 200) {
            that.$message.error(res.msg);
            return;
          }
          if (that.paytype == 1) {
            that.dialogVisible = false;
            if (that.info.film_type == 2) {
              // 隐藏抽奖
              // if (that.is_open == 1) {
              //   that.dialogVisible2 = true;
              // } else {
                that.$message.success("支付成功");
              // }
            }
            that.page = 1;
            that.getdata();
          } else if (that.paytype == 4) {
            that.dialogVisible = false;
            that.code = res.data.code;
            that.query_url = res.data.query_url;
            that.show = true;
            that.timer = window.setInterval(() => {
              setTimeout(() => {
                that.paytime();
              }, 0);
            }, 3000);
          } else if (that.paytype == 5) {
            that.dialogVisible = false;
            if (that.info.film_type == 2) {
              //隐藏抽奖
              // if (that.is_open == 1) {
              //   that.dialogVisible2 = true;
              // } else {
                that.$message.success("支付成功");
              // }
            }
            that.page = 1;
            that.getdata();
          }
        })
        .catch((err) => {
          console.log(err);
          that.$message.error(err.msg);
        });
    },
    // 抽奖说明
    DrawthatBnt() {
      // this.dialogVisible2 = false;
      this.dialogVisible3 = true;
    },
    rndNum(min, max) {
      if (min > max) min = [max, (max = min)][0];
      return Math.floor(Math.random() * (max - min + 1) + min);
    },
    request(name) {
      //模拟抽奖请求
      let that = this;
      if (!that.DrawNum || that.DrawNum == 0) {
        that.$message.error("你当天的抽奖次数已用完~");
        return;
      }
      req
        .post("index/doDraw", {})
        .then((res) => {
          if (res.code != 200) {
            // that.$message.error(res.msg);
            return;
          }
          if (that.list && that.list.length) {
            that.list.forEach((item, index) => {
              if (res.data.id == item.id) {
                this.$refs[name].go(index);
              }
            });
          }
        })
        .catch((err) => {
          console.log(err);
          // that.$message.error(err.msg);
        });
      // setTimeout(() => {
      //   let luckyIndex = this.rndNum(0, 7);
      //   console.log(luckyIndex);
      //   this.$refs[name].go(luckyIndex);
      // }, 100);
    },
    //抽奖动画结束
    onend(data) {
      let that = this;
      that.dialogVisible2 = false;
      // if (data.name == "谢谢惠顾") {
      //   alert("很遗憾您没有中奖");
      //   that.page = 1;
      //   that.getdata();
      //   that.getdrawSetList();
      //   that.drawSet();
      //   that.$router.go(0);
      //   return;
      // }
      req
        .post("index/addDraw", {
          id: data.id,
        })
        .then((res) => {
          if (res.code != 200) {
            // that.$message.error(res.msg);
            return;
          }
          that.page = 1;
          that.getdata();
          that.getdrawSetList();
          that.drawSet();
          that.$router.go(0);
          alert("恭喜您获得：" + data.name);
        })
        .catch((err) => {
          console.log(err);
          // that.$message.error(err.msg);
        });
      console.log("抽奖结果回调：", data);
    },
    formatDate(now) {
      if (now == undefined) {
        return;
      }
      let date = new Date(now);
      //时间戳为10位需*1000，时间戳为13位的话不需乘1000
      let y = date.getFullYear();
      let MM = date.getMonth() + 1;
      MM = MM < 10 ? "0" + MM : MM; //月补0
      let d = date.getDate();
      d = d < 10 ? "0" + d : d; //天补0
      let h = date.getHours();
      h = h < 10 ? "0" + h : h; //小时补0
      let m = date.getMinutes();
      m = m < 10 ? "0" + m : m; //分钟补0
      let s = date.getSeconds();
      s = s < 10 ? "0" + s : s; //秒补0
      // return y + '-' + MM + '-' + d; //年月日
      return y + "-" + MM + "-" + d + " " + h + ":" + m + ":" + s; //年月日时分秒
    },
    // 转换时间戳
    // formatDate(now) {
    //   var date = new Date(now); //时间戳为10位需*1000，时间戳为13位的话不需乘1000
    //   var Y = date.getFullYear() + "-";
    //   var M =
    //     (date.getMonth() + 1 < 10 ? "0" + (date.getMonth() + 1) : date.getMonth() + 1) +
    //     "-";
    //   var D =
    //     (date.getDate() + 1 < 10 ? "0" + (date.getDate() + 1) : date.getDate() + 1) + " ";
    //   // var D = date.getDate() + ' ';
    //   var h =
    //     (date.getHours() + 1 < 10 ? "0" + (date.getHours() + 1) : date.getHours() + 1) +
    //     ":";
    //   // var h = date.getHours() + ':';
    //   var m =
    //     date.getMinutes() + 1 < 10
    //       ? "0" + (date.getMinutes() + 1)
    //       : date.getMinutes() + 1;
    //   // var m = date.getMinutes() + ':';
    //   // var s = date.getSeconds();
    //   return Y + M + D + h + m;
    // },
    openvip() {
      let now = Number(new Date().getTime().toFixed());
      let endday = Number(this.info.end_day) * 1000;
      console.log("nowdate", now);
      console.log("end_day", endday);
      let t = now + endday;
      console.log("t", t);
      let date = this.formatDate(t);
      this.enddate = date;
      if (this.user_level_id == 2) {
        this.concessional = Number(this.info.money) - Number(this.info.vip_money);
      }
      this.dialogVisible = true;
    },
    // 跳转详情
    gofilmdetails(id) {
      this.$router.push({
        path: "/filmdetails",
        query: { type: "filmdetails", moveid: id },
      });
    },
    // 点赞
    giveLike() {
      let that = this;
      req
        .post("index/fabulous", {
          film_id: that.info.id,
        })
        .then((res) => {
          if (res.code != 200) {
            that.$message.error(res.msg);
            return;
          }
          that.$message.success(res.msg);
          that.page = 1;
          that.getdata();
        })
        .catch((err) => {
          console.log(err);
          that.$message.error(err.msg);
        });
    },
    // 收藏
    givecollect() {
      let that = this;
      req
        .post("index/collection", {
          film_id: that.info.id,
        })
        .then((res) => {
          if (res.code != 200) {
            that.$message.error(res.msg);
            return;
          }
          that.$message.success(res.msg);
          that.page = 1;
          that.getdata();
        })
        .catch((err) => {
          console.log(err);
          that.$message.error(err.msg);
        });
    },
    //确定打分
    confirmscore() {
      let that = this;
      req
        .post("index/comment", {
          film_id: that.info.id,
          content: "",
          star: that.value1,
        })
        .then((res) => {
          if (res.code != 200) {
            that.$message.error(res.msg);
            return;
          }
          that.$message.success(res.msg);
          that.page = 1;
          that.getdata();
        })
        .catch((err) => {
          console.log(err);
          that.$message.error(err.msg);
        });
    },
    // 获取页面滚动距离
    handleScroll(e) {
      let el = e.target;
      //判断是否到达div容器底部
      if (el.scrollTop + el.clientHeight >= el.scrollHeight) {
        //控制页数
        this.page = this.page + 1;
        //调用后台接口
        this.getdata();
      }
    },
    // 增加点播量
    addplaynum() {
      let that = this;
      req
        .post("index/addPlay", {
          film_id: that.info.id,
        })
        .then((res) => {
          if (res.code != 200) {
            that.$message.error(res.msg);
            return;
          }
          that.page = 1;
          that.getdata();
        })
        .catch((err) => {
          console.log(err);
          that.$message.error(err.msg);
        });
    },
    getdata(type) {
      let that = this;
      req
        .post("index/filmInfo", {
          id: that.moveid,
          page: that.page,
        })
        .then((res) => {
          if (res.code != 200) {
            that.$message.error(res.msg);
            return;
          }

          that.info = res.data.info;
          that.film = res.data.film;
          that.Drawthattext = res.data.prize_content;
          that.prize = res.data.prize;
          that.DrawNum = res.data.surplus_nums;

          if (res.data.info.is_buy == 1) {
            that.autoplay = false;
          } else {
            if (type == 1) {
              that.addplaynum();
            }
          }
          if (res.data.info.node) {
            that.videoData.currentTime = res.data.info.node;
          }
          if (that.page == 1) {
            that.comment = res.data.comment.data;
          } else {
            if (res.data.comment.data && res.data.comment.data.length) {
              that.comment = [...that.comment, ...res.data.comment.data];
            } else {
              that.$message.error("暂无更多数据");
            }
          }
        })
        .catch((err) => {
          console.log(err);
          // that.$message.error(err.msg);
        });
    },
  },
};
</script>

<style lang="scss">
.filmdetails {
  .w508 {
    width: 31.75rem;
  }
  .w1040 {
    width: 65rem;
  }
  box-sizing: border-box;
  .film_top {
    background: #131316;
    box-shadow: 0px 0.0625rem 0px 0px #f0f0f0;
  }
  .videobox {
    width: 65.625rem;
    height: 39.25rem;
    position: relative;
    margin-right: 1.125rem;
  }
  .comment {
    width: 27.3125rem;
    height: 39.25rem;
    background-color: rgba(51, 51, 51, 0.3);
    &_title {
      position: relative;
      &_text {
        z-index: 2;
      }
      &_box {
        z-index: 1;
        position: absolute;
        left: 8%;
        bottom: -10%;
        z-index: 0;
        width: 3.375rem;
        height: 0.6875rem;
        background: linear-gradient(to right, #ff524c, transparent);
        border-radius: 0.3125rem;
      }
    }
    &_box {
      overflow: auto;
      height: 18rem;
    }
    &_box::-webkit-scrollbar {
      display: none;
    }
  }
  .haopin {
    .el-rate__icon {
      font-size: 1.875rem;
    }
  }
  .btn {
    width: 11.75rem;
    height: 2.75rem;
    background: #ff524c;
    box-shadow: 0.125rem 0.1875rem 0.6875rem 0 rgba(144, 144, 144, 0.14);
    border-radius: 1.375rem;
    font-size: 1rem;
    color: #ffffff;
    line-height: 2.75rem;
    text-align: center;
    cursor: pointer;
  }
  .w197 {
    width: 12.3125rem;
  }
  .is_paybox {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 65.625rem;
    height: 39.25rem;
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: rgba(0, 0, 0, 0.4);
    &_btn {
      padding: 1.375rem 1.8125rem;
      border-radius: 6.25rem;
      font-size: 1.625rem;
      color: #ffffff;
      background: #ff524c;
      box-shadow: 0.125rem 0.1875rem 0.6875rem 0 rgba(144, 144, 144, 0.14);
    }
  }
  .paytype {
    box-sizing: border-box;
    width: 10.9375rem;
    height: 4.375rem;
    padding-left: 1.625rem;
    background: #ffffff;
    border: 0.0625rem solid #eeeeee;
    cursor: pointer;
  }
  .paytypeact {
    box-sizing: border-box;
    width: 10.9375rem;
    height: 4.375rem;
    padding-left: 1.625rem;
    background: #ffffff;
    border: 0.0625rem solid #e7524c;
    cursor: pointer;
  }
  .paybtn {
    width: 12.0625rem;
    height: 2.75rem;
    background: #ff524c;
    box-shadow: 0.125rem 0.1875rem 0.6875rem 0 rgba(144, 144, 144, 0.14);
    border-radius: 1.375rem;
    display: flex;
    align-items: center;
    justify-content: center;
    color: #ffffff;
    cursor: pointer;
  }
  .lottery {
    .zhongjiangminxi {
      width: 5.25rem;
      height: 2.75rem;
      img {
        width: 100%;
        height: 100%;
      }
      position: absolute;
      top: 5%;
      right: 0;
    }
    .el-dialog__header {
      padding: 0;
    }
    .el-dialog__body {
      padding: 0;
    }
    .el-dialog {
      width: fit-content;
    }
    .zjorder {
      width: 23.6875rem;
    }
  }
  /* 为了防止样式重叠 建议给组件加上自定义的class类名 */
  .lottery1 {
    width: 23rem;
    height: 36.75rem;
    background-image: url("../../assets/img/Lotterybg.png");
    background-size: 100% 100%;
    background-repeat: no-repeat;
    position: relative;
    // 抽奖说明
    .Drawthat {
      position: absolute;
      top: 20%;
      right: 0;
      width: 4.875rem;
      height: 1.8125rem;
      display: flex;
      align-items: center;
      justify-content: center;
      background: rgba(255, 255, 255, 0.2);
      color: #ffffff;
      border-radius: 0.5625rem 0px 0px 0.5625rem;
      cursor: pointer;
    }
    //抽奖次数
    .DrawNum {
      position: absolute;
      top: 29%;
      right: 34%;
      font-size: 1.0625rem;
      color: #ffffff;
    }
    /* 奖品列表的蒙层，根据抽奖动画选中的位置被显示出来 */
    .prize__item__mask {
      background-color: rgba(0, 0, 0, 0.3);
    }
    /* 奖品图样式 */
    .prize__item__image {
      width: 2.5625rem;
      height: 2.25rem;
    }
    /* 九宫格组件样式 为了跟随羡慕的自适应方案 */
    .lattice__lottery__box {
      margin: 0 auto;
      margin-top: 13rem;
      width: 16.25rem;
    }
    /* 奖品块样式 为了跟随羡慕的自适应方案 */
    .lattice__lottery__item {
      width: 5rem;
      height: 5rem;
      margin-bottom: 0.4375rem;
      border: 0;
      font-size: 0.75rem;
      color: #ff4b0d;
    }
    /* 抽奖按钮 */
    .lattice__lottery__btn {
      width: 13.1875rem;
      height: 4.1875rem;
      background-image: url("../../assets/img/Lotterybtn.png");
      background-size: 100% 100%;
      background-repeat: no-repeat;
      border: 0;
      color: transparent;
      /* lottery-list 组件的抽奖按钮 */
      &.list {
        margin-top: 1.875rem;
      }
    }
    /* 抽奖动画处于选中状态的奖品块样式 */
    ::v-deep .lattice__lottery__actItem {
      background-color: rebeccapurple;
      color: white;
    }
    .el-dialog__title {
      font-size: 1.25rem;
      color: #333333;
    }
  }
  .lottery_box {
    width: 18.4375rem;
  }
}
.minxi {
  height: 30rem;
  &_date {
    padding: 1.375rem 1.125rem 1rem;
    background-color: #f2f2f2;
    color: #26252d;
    font-weight: bold;
  }
  &_ul {
    padding: 1.75rem 1.375rem 0.3125rem;
  }
  &_li {
    margin-bottom: 2.5rem;
  }
}
</style>
